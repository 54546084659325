import crm from "./crm";

export const AtendimentoService = {
    buscarGravacao:(callSid:any)=> {
      return crm.get(`/oportunidade/atendimento/gravacao/${callSid}`);
    },
    searchAtendimentoInfo: (id_oportunidade_atendimento: number) => {
        return crm.get(`/oportunidade/atendimento/info/${id_oportunidade_atendimento}`);
    },
    criaAtendimento: (atendimento: number) => {
      return crm.post(`/oportunidade/atendimento`, {atendimento});
  }
};
