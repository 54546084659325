import './index.css';
import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown} from "@fortawesome/free-solid-svg-icons";

function DommusToggle(props) {
	const [toggle, setToggle] = useState(props.toggle || false);

    useEffect(() => {
        setToggle(props.toggle);
    }, [props.toggle])

	const headerClass = toggle ? 'dommus-toggle' : 'dommus-toggle arrow';
	return (
		<>
			<header className={headerClass}>{props.title}
				{props.icones ? (
					<div className='dommus-toggle-icones'>
						{props.icones}
						<FontAwesomeIcon icon={faAngleDown} onClick={() => setToggle(!toggle)}/>
					</div>
				) : <FontAwesomeIcon icon={faAngleDown} onClick={() => setToggle(!toggle)}/>}
			</header>
			<div 
				className={props.altura || ''} 
				style={{
					'display': (toggle) ? 'block' : 'none', 
					backgroundColor: props.backgroundColor ?? '',
					height: props.height || 'inherit'
				}}>
				{toggle}
				{props.children}
			</div>
		</>
	)
}

export default DommusToggle;
