import React, { useContext, useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "../DetalheOportunidade/index.css";
import ModalDommus from "../../components/Modal";
import Cadastro from "./Cadastro";
import Atendimento from "./Atendimento";
import Anexos from "./Anexos";
import Pendencias from "./Pendencias";
import Interesse from "./Interesse";
import Status from "./Status";
import NovaDesistencia from "./Desistencia/Nova";
import Desistencia from "./Desistencia";
import {
  faAngleDoubleRight,
  faPhoneVolume,
  faEnvelopeOpenText,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OportunidadeService from "../../services/OportunidadeService";
import Swal from "sweetalert2";
import { trackPromise } from "react-promise-tracker";
import ReactTooltip from "react-tooltip";
import stringHelper from "../../helpers/StringHelper";
import { successToast } from "../../components/DommusToast";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import guService from "../../services/GuService";
import moment from "moment";
import {validaCPF} from '../../components/InputTextMask'
import DesistenciaService from "../../services/DesistenciaService";
import DommusCheckboxToggle from "../../components/DommusCheckboxToggle";
import oportunidadeService from "../../services/OportunidadeService";
import ConfiguracoesGestaoService from "../../services/ConfiguracoesGestaoService";
import { EmpreendimentoContext, OportunidadeContext } from "../../components/GlobalContext/globalContext";
import { InformacoesVendaGanha } from "./InformacoesVendaGanha";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import NovoCadastro from "./Cadastro/NovoCadastro";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { TiposDocumentosContext } from "../../contexts/TiposDocumentosContext/TiposDocumentosContext";
import { TiposPendenciasContext } from "../../contexts/tiposPendenciasContext/TiposPendenciasContext";
import { QuestionariosOportunidade } from "./QuestionariosOportunidade";
import { QuestionarioContext } from "../../contexts/QuestionarioContext";
import { FunilService } from "../../services/FunilService";
import { MeiosComunicacaoContext } from "../../contexts/MeiosComunicacaoContext";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import equipeService from "../../services/EquipeService";
import MensagemWhatsapp from "./Cadastro/MensagemWhatsapp";

export default function DetalheOportunidade(props) {
  const [expiracao, setExpiracao] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [openStatus, setOpenStatus] = useState(false);
  const [openNovaDesistencia, setOpenNovaDesistencia] = useState(false);
  const [openDesistencia, setOpenDesistencia] = useState(false);
  const [atendimentos, setAtendimentos] = useState({});
  const [pendencias, setPendencias] = useState({});
  const [envolvidos, setEnvolvidos] = useState([]);
  const [desistencias, setdesistencias] = useState([])
  const [permissoesOcultarDados, setPermissoesOcultarDados] = useState([])
  const [interesses, setInteresses] = useState({});
  const [anexos, setAnexos] = useState({});
  const [refresh, setRefresh] = useState(false)
  const [openModalVendaGanha, setOpenModalVendaGanha] = useState(false)
  const zeroPad = (num, places) => String(num).padStart(places, "0");
  const contextoEmpreendimentoGlobal = useContext(EmpreendimentoContext)
  const [openCadastroEnvolvido, setOpenCadastroEnvolvido] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [openModalMensagemWhatsapp, setOpenModalMensagemWhatsapp] = useState(false);
  const [envolvido, setEnvolvido] = useState({});
  const [idOportunidade, setIdOportunidade] = useState({})
  const [openModalNovo, setOpenModalNovo] = useState(false)
  const [listaCamposDinamicos, setListaCamposDinamicos] = useState([])
  const [funis, setFunis] = useState(null)
  let { id } = props.id ? props : useParams();
  const params = new URLSearchParams(useLocation().search);
  const {recarregarTiposDocumentos} = useContext(TiposDocumentosContext);
  const {recarregarTiposPendencias} = useContext(TiposPendenciasContext);
  const {recarregarQuestionarios, recarregarRespostas} = useContext(QuestionarioContext);
  const [visualizacaoAbasOportunidade, setVisualizacaoAbasOportunidade] = useState();
  const temPermissaoCA = guService.validaPermissaoModulo(guService.ID_MODULO_CENTRAL_ATENDIMENTO)
  const {buscarConfiguracaoMeiosComunicacao, configuracoesMeioComunicacao, verificarConfiguracaoMeioComunicacao} = useContext(MeiosComunicacaoContext);
  const {setNovoTipoAtendimento, setOportunidadeSelecionada, oportunidadeSelecionada} = useContext(OportunidadeContext);

  useEffect(() => {
    trackPromise(ConfiguracoesGestaoService.buscarConfiguracao("desbloqueia_campos"))
      .then(res=> {
        setPermissoesOcultarDados(res.data)
      }).catch(error => Swal.fire({
        titleText: "Ooops...",
        text: "Ocorreu um erro ao buscar as permissões para ocultar dados: " + error,
        icon: "error"
      }))

    let aba = params.get("aba");
    switch (aba) {
      case "qualificacao":
        setTabIndex(1);
        break;
      case "atendimento":
        setTabIndex(2);
        break;
      case "anexos":
        setTabIndex(3);
        break;
      case "pendencias":
        setTabIndex(4);
        break;
    }

    buscarConfiguracaoMeiosComunicacao()
    recarregarTiposDocumentos();
    recarregarTiposPendencias();
    recarregarQuestionarios();    

    trackPromise(ConfiguracoesGestaoService.buscarConfiguracao("visualizacao-abas-detalhe-oportunidade")).then((response)=>{
        setVisualizacaoAbasOportunidade(response.data);    
    }).catch((error)=>{
      Swal.fire({
        titleText: "Ooops...",
        text: "Ocorreu um erro ao configuração" + error,
        icon: 'error'
      });
    });
    carregarFunis()
  }, []);

  useEffect(() => {
    trackPromise(
      OportunidadeService.buscarOportunidade(id).then(
        (response) => {
          const oportunidadeCarregada = Object.assign({}, response.data)
          setOportunidadeSelecionada(oportunidadeCarregada);
          setIdOportunidade(oportunidadeCarregada?.id_oportunidade)
          handleCheckCondicoesEtapaAtual(oportunidadeCarregada?.id_oportunidade);
        },
        (error) => {
          Swal.fire({
            titleText: "Sem permissão de acesso à oportunidade",
            text: "Verifique se você já teve acesso a esta oportunidade ou se realizou a transferência da mesma para outro atendente",
            icon: "warning",
            showCloseButton: false,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: "Voltar para lista de oportunidades",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              window.location.replace("/");
            }
          });
        }
      )
    )
  }, [refresh]);

  useEffect(() => {
    buscaDesistencias()
  }, [openNovaDesistencia])

  useEffect(() => {
    if (oportunidadeSelecionada) {
      oportunidadeService.buscarCamposDinamicosTelaQualificacao()
      .then((res) => {
          setListaCamposDinamicos(res.data.campos);
      }).catch(error => {
          Swal.fire({
              titleText: "Ooops...",
              text: "Ocorreu um erro ao buscar campos: " + error,
              icon: 'error'
          });
      });

      OportunidadeService.buscaExpiracao(oportunidadeSelecionada.id_oportunidade).then(
        (response) => {
          const dadoExpiracao = response.data;
          if (dadoExpiracao != "") {
            let data = moment(oportunidadeSelecionada.atualizado_em)
              .add(Number(dadoExpiracao), "minutes")
              .format("DD/MM/YYYY HH:mm");
            setExpiracao(data);
          }
        },
        (error) => {}
      );

      OportunidadeService.buscarListaAtendimentos(oportunidadeSelecionada.id_oportunidade).then(
        (response) => {
          setAtendimentos(Object.values(response.data));
        },
        (error) => {
          Swal.fire({
            titleText: "Ooops...",
            text: "Ocorreu um erro ao buscar os atendimentos: " + error.data,
            icon: "error",
          });
        }
      );
      OportunidadeService.buscarListaEnvolvidos(
        oportunidadeSelecionada.id_oportunidade
      ).then(
        (response) => {
          const novosEnvolvidos = response.data.slice();
          setEnvolvidos(novosEnvolvidos);
        },
        (error) => {
          Swal.fire({
            titleText: "Ooops...",
            text: "Ocorreu um erro ao buscar os envolvidos: " + error,
            icon: "error",
          });
        }
      )
      

      OportunidadeService.buscarListaAnexos(oportunidadeSelecionada.id_oportunidade).then(
        (response) => {
          setAnexos(Object.values(response.data));
        },
        (error) => {
          // Swal.fire({
          //   titleText: "Ooops...",
          // text: "Ocorreu um erro ao buscar os anexos: " + error.data,
          //   icon: "error"
          // });
        }
      );

      OportunidadeService.buscarListaPendencias(
        oportunidadeSelecionada.id_oportunidade
      ).then(
        (response) => {
          setPendencias(Object.values(response.data));
        },
        (error) => {
          Swal.fire({
            titleText: "Ooops...",
            text: "Ocorreu um erro ao buscar as pendências: " + error.data,
            icon: "error",
          });
        }
      );
      equipeService.buscarCoordenadoresPorEquipeEmpreendimento((oportunidadeSelecionada?.id_equipe_pdv ?? oportunidadeSelecionada?.id_equipe_pre_atendimento), oportunidadeSelecionada?.id_empreendimento)
      .then((response)=>{
        contextoEmpreendimentoGlobal.setCoordenadores(response.data);
      })

      atualizaInteresse();
    }
  }, [oportunidadeSelecionada, refresh]);



  function handleCheckCondicoesEtapaAtual(idOportunidade){
    trackPromise(OportunidadeService.checkCondicoesEtapaAtual(idOportunidade)).then((response)=>{
      if(response.data){
        Swal.fire({
          title: 'Condições da Etapa',
          text: `${response.data.messagem}. O que deseja?`,
          icon: 'question',
          confirmButtonText: 'Retroceder Etapa',
          confirmButtonColor: "#f7941d",
          showCancelButton: false,
          showDenyButton: true,
          denyButtonText: 'Registrar Atividade',
          denyButtonColor: "#183b64",
          allowOutsideClick: false,
        }).then(result => {
            if(result.isConfirmed) {
              setOpenStatus(true);
            }else if(result.isDenied){
              switch (response.data.tipo) {
                case "Telefonema":
                  setTabIndex(2)
                  setNovoTipoAtendimento(1);
                  setOpenModalNovo(true);
                  break;
                case "E-mail":
                  setTabIndex(2)
                  setNovoTipoAtendimento(2);
                  setOpenModalNovo(true);
                  break;                
                case "Mensagem":
                  setTabIndex(2)
                  setNovoTipoAtendimento(3);
                  setOpenModalNovo(true);
                  break;                
                case "Tarefa Agendada":
                case "Tarefa Concluída":
                  setTabIndex(2)
                  setNovoTipoAtendimento(5);
                  setOpenModalNovo(true);
                  break;                
                case "Visita Agendada":
                case "Visita Concluída":
                case "Visita Concluída (SIM)":
                case "Visita Concluída (NÃO)":
                  setTabIndex(2)
                  setNovoTipoAtendimento(4);
                  setOpenModalNovo(true);
                  break;                
                case "Comentário":
                  setTabIndex(2)
                  setNovoTipoAtendimento(7);
                  setOpenModalNovo(true);
                  break;                
                case "Proposta":
                  setTabIndex(2)
                  setNovoTipoAtendimento(6);
                  setOpenModalNovo(true);
                  break;                
                case "Anexo":
                  setTabIndex(3)
                  break;              
                default:                 
                  break;
              }
            } 
        })
      }
    }).catch((error)=>{
      Swal.fire({
        titleText: "Ooops...",
        text: "Ocorreu um erro ao checar etapa atual" + error,
        icon: 'error'
      });
    })
  }

  function carregarFunis() {
    FunilService.index().then((res) => {
      const funisFiltrados = res.data.filter(funil => {
        funil.funil_status_oportunidade = funil.funil_status_oportunidade.filter(funil_status_oportunidade => funil_status_oportunidade.status_oportunidade)
        
        return funil
      })
      setFunis(funisFiltrados)
    })
  }

  const handleInformarDesistencia = () => {
    let dadosDinamicos = oportunidadeSelecionada?.interesse?.dados_dinamicos ? JSON.parse(oportunidadeSelecionada?.interesse?.dados_dinamicos ) : null;
    let validaCampos = [];
    let camposVazios = []; 
    if(dadosDinamicos){
      Object.values(listaCamposDinamicos).forEach(camposlista => {
        let campo = (camposlista?.etapas_vinculadas?.filter(campos => [campos].includes(oportunidadeSelecionada.status.id_status_oportunidade)))
        if (Array.isArray(campo) && campo.length ? true : false) {
            validaCampos.push(camposlista);
        }
    })
    validaCampos.forEach(camposValidados => {
        if (dadosDinamicos[camposValidados.id_campos_dinamicos]) {
            camposVazios.push(camposValidados.label);
        }
    })
    }
   
    let nomeCampos = camposVazios.toString();
    if (Array.isArray(camposVazios) && camposVazios.length ? true : false) {
        Swal.fire({
            title: 'Parece que há campos obrigatórios sem preencher, gostaria de preenche-los?',
            text: `Campos: ${nomeCampos}`,
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: 'Sim',
            denyButtonText: 'Não'
        }).then((result) => {
            if (result.isConfirmed) {
                setTabIndex(1)
                closeModal()
            }
        })
    } else {
    setOpenNovaDesistencia(true)
  }
  }

  function mudaParaAba(aba, abrirModalNovo = false) {
    setTabIndex(aba)
    setOpenModalNovo(abrirModalNovo)
  }

  function buscaVinculoVende() {
    const empreendimento = contextoEmpreendimentoGlobal.empreendimentos?.find(empreendimento => empreendimento.id == oportunidadeSelecionada.id_empreendimento)

    return empreendimento && empreendimento?.vinculo_prospecta_vende == "S" ? true : false
  }

  function atualizaInteresse() {
    OportunidadeService.buscarListaInteresses(
      oportunidadeSelecionada.id_oportunidade
    ).then(
      (response) => {
        setInteresses(response.data);
      },
      (error) => {
        Swal.fire({
          titleText: "Ooops...",
          text: "Ocorreu um erro ao buscar os interesses: " + error.data,
          icon: "error",
        });
      }
    );
  }

  async function buscaDesistencias() {
    await DesistenciaService.buscarDesistencias(id).then((response) => {
        if (response.data !== undefined) {
          setdesistencias(response.data)
        }
      }).catch(error => Swal.fire({
          titleText: "Ooops...",
          text: "Ocorreu um erro ao buscar os dependencias: " + error,
          icon: "error"
      }))
    
  }

  function iniciarDocProp() {
    //validacaocpf
    let verificacao = validaCPF(envolvidos[0].cpf)

    if(!verificacao){
      Swal.fire({
        titleText: "Erro de Validação",
        text: "O CPF do envolvido não é válido.",
        icon: "error",
      });
      return false;
    }

    if (
      stringHelper.isBlank(envolvidos[0].nome) ||
      stringHelper.isBlank(envolvidos[0].cpf) ||
      stringHelper.isCpf(envolvidos[0].cpf) != true ||
      stringHelper.isBlank(envolvidos[0].telefone) ||
      stringHelper.isBlank(envolvidos[0].email) ||
      !(interesses || false) ||
      (Array.isArray(interesses) && interesses.length === 0)
    ) {
      Swal.fire({
        titleText: "Erro de Validação",
        text: "Os seguintes dados do envolvido devem ser preenchidos: CPF, Nome, Email e Telefone. O interesse não pode estar vazio.",
        icon: "error",
      });
      return false;
    }
 
    Swal.fire({
      titleText: "Documentação & Proposta",
      text: "Tem certeza que deseja iniciar a fase de Documentação & Proposta?",
      icon: "question",
      showCloseButton: false,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      customClass: {
        confirmButton: "btn btn-primary mr-3",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        var win = window.open(oportunidadeSelecionada.link_cadastro, "_blank");
        win.focus();
      }
    });
  }

  function abrirProcesso() {
    var win = window.open(
      guService.getLocalState("linkVendas") +
        "index_ui.php?mgr=MQ==&ui=NjM=&id_processo=" +
        oportunidadeSelecionada.id_processo,
      "_blank"
    );
    win.focus();
  }

  function enviarPdv() {
    Swal.fire({
      titleText: "Enviar para Atendimento",
      text: "Tem certeza que deseja enviar a oportunidade para um corretor?",
      icon: "question",
      showCloseButton: false,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Sim, enviar!",
      cancelButtonText: "Não, manter em pré-atendimento",
      customClass: {
        confirmButton: "btn btn-primary mr-3",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.value) {
        trackPromise(
          OportunidadeService.enviarParaAtendimentoPdv(oportunidadeSelecionada)
            .then(
              (response) => {
                const novaOportunidade = Object.assign({}, oportunidadeSelecionada);
                novaOportunidade.status = response.data.status;
                setOportunidadeSelecionada(novaOportunidade);
                successToast.fire({
                  text: "Enviado com sucesso para " + response.data.recebeu,
                });
              },
              (error) => {
                throw error;
              }
            )
            .catch((error) => {

              if(error.response.status === 403){
                Swal.fire({
                  titleText: "Ooops...",
                  text: error.response.data,                    
                  icon: "info",
                });
              }else{
                Swal.fire({
                  titleText: "Ooops...",
                  text:
                    "Ocorreu um erro ao enviar a oportunidade para atendimento: " +
                    ((error.response && error.response.data) || error),
                  icon: "error",
                });
              }
            })
        );
      }
    });
  }

  // function verificarConfiguracaoMeioComunicacao(config) {
  //   switch(configuracoesMeioComunicacao[config]) {
  //     case EnumConfiguracaoMeioComunicacao.CONFIG_MEIO_COMUNICACAO_DESATIVADO:
  //       Swal.fire({
  //         titleText: "Central de Atendimento não ativada",
  //         text: "Entre em contato com o departamento comercial da Dommus e solicite, agora mesmo, sua ativação.",
  //         icon: "warning",
  //       });
  //       break;
  //     case EnumConfiguracaoMeioComunicacao.CONFIG_MEIO_COMUNICACAO_CONECTA:
  //       Swal.fire({
  //         titleText: "Abrir atendimento pelo Dommus Conecta?",
  //         text: `Deseja confirmar a criação de um novo atendimento pelo Dommus Conecta via ${config}?`,
  //         icon: "question",
  //         showCancelButton: true,
  //         cancelButtonText: 'Não, cancelar.',
  //         confirmButtonText: 'Sim, confirmar.'
  //       }).then(result => {
  //         if(result.isConfirmed) {
  //           OportunidadeService.criarAtendimentoConecta(config, oportunidade.id_oportunidade)
  //             .then(res => {
  //               guService.buscarTokenAcessoModulo(guService.ID_MODULO_CENTRAL_ATENDIMENTO).then(resposta => {
  //                 window.open(`${process.env.REACT_APP_CONECTA_FRONT}/gu-login/${resposta.data.token}:${res.data.id || res.data.conteudo.id}`, '_blank')
  //               })
  //             }).catch(erro => {
  //               swalErro(`${erro.response?.data?.mensagem}`)
  //             })
  //         }
  //       });
  //       break;
  //     case EnumConfiguracaoMeioComunicacao.CONFIG_MEIO_COMUNICACAO_PROSPECTA:
  //       //TODO: Implementar ação através dos widgets.
  //       break;
  //   }
  // }

  function ocultaDados(status) {
    let data = {
      oportunidade: {
        id_oportunidade: oportunidadeSelecionada.id_oportunidade,
        ocultar_dados: status
      }
    }
    oportunidadeService.salvarOportunidade(data)
      .catch((error) => {
        Swal.fire({
            titleText: "Ooops...",
            text: "Ocorreu um erro ao buscar ocultar os dados da opv: " + error,
            icon: 'error'
        });
    })
  }

  function cadastroEnvolvido() {
    setEnvolvido({});
    setOpenCadastroEnvolvido(true)
  }

  function atualizarEnvolvidos(atualiza = false) {
    OportunidadeService.buscarListaEnvolvidos(oportunidadeSelecionada).then(
      (response) => {
        const clientesAtualizada = response.data.slice();
        setClientes(clientesAtualizada);
        setRefresh(!refresh)
      },
      (error) => {
        Swal.fire({
          titleText: "Ooops...",
          text: "Ocorreu um erro ao buscar os envolvidos: " + error,
          icon: "error",
        });
      }
    );
  }

  const atualizarStatusOportunidade = (novoStatus) => {
    const novaOportunidade = Object.assign(oportunidadeSelecionada, {status:novoStatus});
    
    setOportunidadeSelecionada(novaOportunidade);
  }

  const atualizarOportunidade = (oportunidade) => {
    setOportunidadeSelecionada(oportunidade);
  }

  return (
    <>
      <ReactTooltip />
      {oportunidadeSelecionada && (
        <>
        {(isDesktop || isTablet) &&
          <div className="tituloPagina">
            <span className="nomeOportunidadeMobile">
              {oportunidadeSelecionada.envolvidos_oportunidade?.[0]?.envolvido?.nome  || oportunidadeSelecionada.leads_oportunidade?.[0]?.lead?.nome || 'Nome Não Informado'}
              <span className="codigoMobile">
                {" "}
                (OPV-{zeroPad(oportunidadeSelecionada.id_oportunidade, 5)})
              </span>
            </span>
            <span className="nomeOportunidade">
              Oportunidade:{" "}
              {oportunidadeSelecionada.envolvidos_oportunidade?.[0]?.envolvido?.nome  || oportunidadeSelecionada.leads_oportunidade?.[0]?.lead?.nome || 'Nome Não Informado'}
              <span className="codigo">
                {" "}
                (OPV-{zeroPad(oportunidadeSelecionada.id_oportunidade, 5)})
              </span>
            </span>
            <span className="statusCodigo">
            {permissoesOcultarDados.includes(Number(guService.getLocalState("tipoUsuario"))) && (
              <DommusCheckboxToggle
                className="switch-opv"
                value={oportunidadeSelecionada.ocultar_dados}
                setValue={ (novoStatus) => ocultaDados(novoStatus ? 1 : 0) }
                label="Ocultar Dados Públicos"
                tooltip="Ocultar Dados Públicos"
              />
            )}
              <span>
                {
                  desistencias.length > 0 ?
                  <button
                    onClick={() => setOpenDesistencia(true)}
                    className="BtnDesistencia"
                  >
                    Dados da desistência
                  </button> :
                  oportunidadeSelecionada?.usuario_pode_editar ? 
                  <button
                    onClick={() => handleInformarDesistencia()}
                    className="BtnDesistencia"
                  >
                    Informar desistência
                  </button>
                  :<></>
                }
              </span>
              <div
                className="status"
                style={{ backgroundColor: oportunidadeSelecionada?.status?.cor, marginRight: '10px', cursor: `${oportunidadeSelecionada?.usuario_pode_editar ? "pointer" : "not-allowed"}` }}
                onClick={                  
                  () => {
                    if(oportunidadeSelecionada?.usuario_pode_editar){
                      setOpenStatus(true)
                    }
                  }
                }
              >
                {oportunidadeSelecionada?.status?.nome}
              </div>
              {
                oportunidadeSelecionada?.substatus_oportunidade !== null ?
                <div
                  className="status"
                  style={{ backgroundColor: oportunidadeSelecionada?.substatus_oportunidade?.cor }}
                  onClick={() => setOpenStatus(true)}
                >
                  {oportunidadeSelecionada?.substatus_oportunidade?.nome}
                </div> : <></>
              }
            </span>
          </div>
        }
          <div className="conteudoPagina conteudoOportunidade">
            {(isMobile && !isTablet)  &&
              <div className="titulo-pagina-mobile">
                <div className="statusCodigo">       
                  <div
                    className="status"
                    style={{ backgroundColor: oportunidadeSelecionada?.status?.cor, marginRight: '10px', cursor: `${oportunidadeSelecionada?.usuario_pode_editar ? "pointer" : "not-allowed"}` }}
                    onClick={                  
                      () => {
                        if(oportunidadeSelecionada?.usuario_pode_editar){
                          setOpenStatus(true)
                        }
                      }
                    }
                  >
                    {oportunidadeSelecionada?.status?.nome}
                  </div>
                  {
                    oportunidadeSelecionada?.substatus_oportunidade !== null ?
                    <div
                      className="status"
                      style={{ backgroundColor: oportunidadeSelecionada?.substatus_oportunidade?.cor }}
                      onClick={() => setOpenStatus(true)}
                    >
                      {oportunidadeSelecionada?.substatus_oportunidade?.nome}
                    </div> : <></>
                  }
                </div>
                <span className="nome-oportunidade-mobile">
                  {oportunidadeSelecionada.envolvidos_oportunidade?.[0]?.envolvido?.nome  || oportunidadeSelecionada.leads_oportunidade?.[0]?.lead?.nome || 'Nome Não Informado'}
                  <span className="codigoMobile">
                    {" "}
                    (OPV-{zeroPad(oportunidadeSelecionada.id_oportunidade, 5)})
                  </span>
                </span>  
              </div>
            }
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(tabIndex) => setTabIndex(tabIndex)}
            >
              {
                desistencias.length > 0 ?
                <button
                  onClick={() => setOpenDesistencia(true)}
                  className="btn-dados-desistencia-mobile"
                >
                  Dados da desistência
                </button> :
                oportunidadeSelecionada?.usuario_pode_editar ? 
                <button
                className="desistenciaMobile"
                onClick={() => setOpenNovaDesistencia(true)}
                >
                  <p size="lg" color="red" data-tip="Desistência">
                    Informar Desistencia
                  </p>
                </button>
                :<></>
              }
              <div className="container-abaDocPropostaMobile">
                {oportunidadeSelecionada.id_processo !== null && oportunidadeSelecionada?.usuario_pode_editar && (
                  <Tab
                    className="btn docProposta"
                    onClick={() => abrirProcesso()}
                  >
                    Processo de venda Nº:{" "}
                    {zeroPad(oportunidadeSelecionada.id_processo, 5)}
                  </Tab>
                )}
                {(oportunidadeSelecionada?.status?.tipo === "G") && oportunidadeSelecionada?.usuario_pode_editar && (
                  <Tab className="btn docProposta" onClick={() => setOpenModalVendaGanha(true)}>
                    Dados da Venda Ganha <FontAwesomeIcon icon={faAngleDoubleRight} color="#fff" />
                  </Tab>
                )}
                {oportunidadeSelecionada?.usuario_pode_editar ?
                  oportunidadeSelecionada?.status?.tipo === "P" && !oportunidadeSelecionada.id_processo  ? buscaVinculoVende() ? (
                  <Tab className="btn docProposta" onClick={() => iniciarDocProp()}>
                    Documentação & Proposta <FontAwesomeIcon icon={faAngleDoubleRight} color="#fff" />
                  </Tab>
                  ) : (
                    <Tab className="btn btn-success" onClick={() => setOpenModalVendaGanha(true)}>
                      Informar Venda Ganha <FontAwesomeIcon icon={faAngleDoubleRight} color="#fff" />
                    </Tab>
                  ) : (
                    <></>
                  )
                  :<></>
                }
                {oportunidadeSelecionada.status.tipo === "A" && oportunidadeSelecionada?.usuario_pode_editar && (
                  <Tab className="btn docProposta" onClick={() => enviarPdv()}>
                    Enviar para Atendimento
                    <FontAwesomeIcon
                      icon={faAngleDoubleRight}
                      color="#fff"
                    />{" "}
                  </Tab>
                )}
              </div>
              <div className="container-abaDocMobile">
                {(oportunidadeSelecionada?.usuario_pode_editar && temPermissaoCA) ? <>

                  <>
                    {(configuracoesMeioComunicacao?.email) ? (
                      <button className="mailIcon iconTabMobile" onClick={() => verificarConfiguracaoMeioComunicacao('email', oportunidadeSelecionada.id_oportunidade)}>
                        <FontAwesomeIcon icon={faEnvelopeOpenText} color="#F44336" data-tip="Enviar E-mail" />
                      </button>
                    ) : <></>}
                    {(configuracoesMeioComunicacao?.voip) ? (
                      <button className="callIcon iconTabMobile" onClick={() => verificarConfiguracaoMeioComunicacao('voip', oportunidadeSelecionada.id_oportunidade)}>
                        <FontAwesomeIcon icon={faPhoneVolume} color="var(--dommus-color-2)" data-tip="Realizar Chamada" />
                      </button>
                    ) : <></>}
                  </>
                </> : <></>}
                <button className="callIcon iconTabMobile" onClick={() => setOpenModalMensagemWhatsapp(true)}>
                  <FontAwesomeIcon icon={faWhatsapp} color="green" data-tip="Enviar Mensagem" size="lg" />
                </button>
              </div>

              <div className="container-tabs">
                <TabList>
                  <div className="abaBotoes">
                    <Tab className="aba-menu-superior-detalhe-opv">Cadastro</Tab>
                    {visualizacaoAbasOportunidade?.qualificacoes &&
                      <Tab className="aba-menu-superior-detalhe-opv">Qualificação</Tab>
                    }
                    {visualizacaoAbasOportunidade?.atendimentos &&
                      <Tab className="aba-menu-superior-detalhe-opv">Atividade</Tab>}
                    {visualizacaoAbasOportunidade?.anexos &&
                      <Tab className="aba-menu-superior-detalhe-opv">Anexos</Tab>}
                    {visualizacaoAbasOportunidade?.pendencias &&
                      <Tab className="aba-menu-superior-detalhe-opv">Pendências</Tab>}
                    {visualizacaoAbasOportunidade?.questionarios &&
                      <Tab className="aba-menu-superior-detalhe-opv">Questionários</Tab>}
                  </div>
                  <div className="abaDoc">
                    {expiracao != "" ? (
                      <div className="mailIcon iconTab">
                        <span style={{ fontSize: "12px" }}>
                          <FontAwesomeIcon
                            icon={faClock}
                            color="#212529"
                            data-tip={"Data de expiração: " + expiracao}
                          />
                          <span style={{ marginLeft: "5px" }}>
                            Data de expiração: {expiracao}
                          </span>
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}
                    {(temPermissaoCA && oportunidadeSelecionada?.usuario_pode_editar) ? 
                      <>
                        {configuracoesMeioComunicacao?.email ? (
                          <div className="mailIcon iconTab">
                            <FontAwesomeIcon icon={faEnvelopeOpenText} onClick={() => verificarConfiguracaoMeioComunicacao('email', oportunidadeSelecionada.id_oportunidade)} color="#F44336" data-tip="Enviar E-mail"/>
                          </div>
                        ) : <></>}
                        {configuracoesMeioComunicacao?.voip ? (
                          <div className="callIcon iconTab">
                            <FontAwesomeIcon icon={faPhoneVolume} onClick={() => verificarConfiguracaoMeioComunicacao('voip', oportunidadeSelecionada.id_oportunidade)} color="var(--dommus-color-2)" data-tip="Realizar Chamada"/>
                          </div>
                        ) : <></>}
                      </> : <></>
                      
                    }
                    <div className="callIcon iconTab" onClick={() => setOpenModalMensagemWhatsapp(true)}>
                          <FontAwesomeIcon icon={faWhatsapp} color="green" data-tip="Enviar Mensagem" size="lg"/>
                    </div>
                    {oportunidadeSelecionada.id_processo !== null && oportunidadeSelecionada?.usuario_pode_editar && (
                      <Tab
                        className="docProposta"
                        onClick={() => abrirProcesso()}
                      >
                        Processo de venda Nº:{" "}
                        {zeroPad(oportunidadeSelecionada.id_processo, 5)}
                      </Tab>
                    )}
                    {(oportunidadeSelecionada?.status?.tipo === "G") && oportunidadeSelecionada?.usuario_pode_editar && (
                      <Tab className="docProposta" onClick={() => setOpenModalVendaGanha(true)}>
                        Dados da Venda Ganha <FontAwesomeIcon icon={faAngleDoubleRight} color="#fff" />
                      </Tab>
                    )}
                    {oportunidadeSelecionada?.usuario_pode_editar ?
                      oportunidadeSelecionada?.status?.tipo === "P" ? buscaVinculoVende() ? (
                      <Tab className="btn docProposta" onClick={() => iniciarDocProp()}>
                        Documentação & Proposta <FontAwesomeIcon icon={faAngleDoubleRight} color="#fff" />
                      </Tab>
                      ) : (
                        <Tab className="btn btn-success" onClick={() => setOpenModalVendaGanha(true)}>
                          Informar Venda Ganha <FontAwesomeIcon icon={faAngleDoubleRight} color="#fff" />
                        </Tab>
                      ) : (
                        <></>
                      )
                      :<></>
                    }
                    {oportunidadeSelecionada.status.tipo === "A" && oportunidadeSelecionada?.usuario_pode_editar && (
                      <Tab className="docProposta" onClick={() => enviarPdv()}>
                        Enviar para Atendimento
                        <FontAwesomeIcon
                          icon={faAngleDoubleRight}
                          color="#fff"
                        />{" "}
                      </Tab>
                    )}
                  </div>
                </TabList>
              </div>
              <TabPanel>
                {envolvidos.length > 0 ? (
                  <Cadastro
                    listaLeads={oportunidadeSelecionada.leads_oportunidade}
                    oportunidade={oportunidadeSelecionada}
                    setTabIndex={setTabIndex}
                    dados={envolvidos}
                    interesses={interesses}
                    pendencias={pendencias}
                    dadosOportunidade={oportunidadeSelecionada}
                    atendimentos={atendimentos}
                    anexos={anexos}
                    setRefreshEnvolvido={setRefresh}
                    refreshEnvolvido={refresh}
                    atualizarOportunidade={atualizarOportunidade}
                  />
                ) : (
                  <>
                    <div className="alert warning" onClick={() => cadastroEnvolvido()}>
                        <FontAwesomeIcon icon={faPlus} style={{marginRight: '15px'}}/>
                        Não existe envolvido cadastrado, Clique aqui para Cadastrar.
                    </div>
                  </>
                )}
              </TabPanel>
              {visualizacaoAbasOportunidade?.qualificacoes && (
                <TabPanel>
                  <Interesse
                    oportunidade={oportunidadeSelecionada}
                    id_empreendimento={oportunidadeSelecionada.id_empreendimento}
                    dados={interesses}
                    atualiza={atualizaInteresse.bind(this)}
                  />
                </TabPanel>
              )}
              {visualizacaoAbasOportunidade?.atendimentos && (
                <TabPanel>
                  <Atendimento
                    atualizarEnvolvidos={atualizarEnvolvidos}
                    oportunidade={oportunidadeSelecionada}
                    dados={atendimentos}
                    setTabIndex={setTabIndex}
                    abrirModalNovo={openModalNovo}
                    atualizarOportunidade={setRefresh}
                    setAbriModalNovo={setOpenModalNovo}
                  />
                </TabPanel>
              )}
              {visualizacaoAbasOportunidade?.anexos && (
                <TabPanel>
                  <Anexos oportunidade={oportunidadeSelecionada} dados={anexos} />
                </TabPanel>
              )}
              {visualizacaoAbasOportunidade?.pendencias && (
                <TabPanel>
                  <Pendencias
                    oportunidade={oportunidadeSelecionada}
                    dados={pendencias}
                  />
                </TabPanel>
              )}
              {visualizacaoAbasOportunidade?.questionarios && (
                <TabPanel>
                  <QuestionariosOportunidade oportunidade={oportunidadeSelecionada}/>
                </TabPanel>
              )}
            </Tabs>
            <ModalDommus
              open={openStatus}
              close={setOpenStatus.bind(false)}
              size={'md'}
              content={
                <Status
                  funis={funis}
                  camposDinamicos={listaCamposDinamicos}
                  id_oportunidade={oportunidadeSelecionada.id_oportunidade}
                  oportunidade={oportunidadeSelecionada}
                  statusAtual={oportunidadeSelecionada.status.id_status_oportunidade}
                  mostrarModalNovoAtendimento={true}
                  mudaParaAtendimento={mudaParaAba}
                  setTabIndex={setTabIndex}
                  id_substatus_oportunidade={oportunidadeSelecionada.id_substatus_oportunidade}
                  manipuladorMudancaStatus={atualizarStatusOportunidade}
                  closeModal={() => setOpenStatus(false)}
                />
              }
              titulo="Alterar Etapa e Status"
            />

            <ModalDommus
              open={openNovaDesistencia}
              close={setOpenNovaDesistencia.bind(false)}
              content={
                <NovaDesistencia
                  id_oportunidade={oportunidadeSelecionada.id_oportunidade}
                  id_status_oportunidade={oportunidadeSelecionada.status.id_status_oportunidade}
                  id_substatus_oportunidade={
                    oportunidadeSelecionada.id_substatus_oportunidade != null ?
                    oportunidadeSelecionada.id_substatus_oportunidade.id_substatus_oportunidade :
                    oportunidadeSelecionada.id_substatus_oportunidade
                  }
                  nao_pertube={oportunidadeSelecionada.status.tipo === 'NP' ? 1 : 0}
                  close={setOpenNovaDesistencia.bind(false)}
                  setRefreshDesistencia={setRefresh}
                />
              }
              titulo="Nova Desistência"
            />
            <ModalDommus
              open={openDesistencia}
              close={setOpenDesistencia.bind(false)}
              content={
                <Desistencia id_oportunidade={id} />
              }
              titulo="Desistência"
            />
          </div>
        </>
      )}
      <ModalDommus
        open={openModalVendaGanha}
        close={() => setOpenModalVendaGanha(false)}
        children={
          <InformacoesVendaGanha
            close={() => setOpenModalVendaGanha(false)}
            setRefresh={setRefresh}
            oportunidade={oportunidadeSelecionada}
          />
        }
        titulo={oportunidadeSelecionada?.status?.tipo === "G" ? "Atualizar Dados da Venda" : "Registrar Dados da Venda"}
        size="md"
      />
      <ModalDommus
        open={openCadastroEnvolvido}
        close={() => setOpenCadastroEnvolvido(false)}
        children={
          <NovoCadastro
            envolvido={envolvido}
            atualizarLista={atualizarEnvolvidos}
            id_oportunidade={idOportunidade}
            envolvidos={clientes}
            closeModal={()=>{setOpenNovoCadastro(false)}}
          />
        }
        titulo={"Novo Envolvido"}
      />
       <ModalDommus
        open={openModalMensagemWhatsapp}
        close={() => setOpenModalMensagemWhatsapp(false)}
        children={
          <MensagemWhatsapp
            envolvidos={envolvidos}
            oportunidadeSelecionada={oportunidadeSelecionada}
            configuracoesMeioComunicacao={configuracoesMeioComunicacao}

            closeModal={()=>{setOpenModalMensagemWhatsapp(false)}}
          />
        }
        titulo={"Enviar Mensagem via Whatsapp"}
      />
    </>
  );
}
