import React, { useContext, useState } from 'react'
import { MeiosComunicacaoContext } from '../../../../contexts/MeiosComunicacaoContext';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { celularMask } from '../../../../components/InputTextMask';
import Swal from 'sweetalert2';
import { trackPromise } from 'react-promise-tracker';
import { AtendimentoService } from '../../../../services/AtendimentoService';
import { PhoneHelper } from '../../../../helpers/PhoneHelper';
import guService from '../../../../services/GuService';


export default function MensagemWhatsapp({ envolvidos, oportunidadeSelecionada, configuracoesMeioComunicacao, envolvidoInicial = null }) {
    const { verificarConfiguracaoMeioComunicacao } = useContext(MeiosComunicacaoContext);
    const [envolvidoSelecionado, setEnvolvidoSelecionado] = useState(envolvidoInicial)
    const [mensagem, setMensagem] = useState("")

    const hasConecta = guService.validaPermissaoModulo(guService.ID_MODULO_CENTRAL_ATENDIMENTO);

    const handleSend = () => {
        if (!envolvidoSelecionado) {
            Swal.fire({
                text: "Por favor, selecione um número.",
                icon: 'warning'
            });
            return
        }
        if (!mensagem.trim()) {
            Swal.fire({
                text: "Por favor, digite uma mensagem.",
                icon: 'warning'
            });
            return
        }
        const mensagemUriEnconded = encodeURIComponent(mensagem)
        const envolvido = envolvidos.find(env => env.id_envolvido == envolvidoSelecionado)

        if (!envolvido?.celular) {
            Swal.fire({
                text: "O envolvido não posssui número válido!.",
                icon: 'warning'
            });
            return
        }


        let atendimento = {
            id_oportunidade: oportunidadeSelecionada.id_oportunidade,
            contato: envolvido?.celular,
            id_tipo_atendimento: 3,
            observacao: "Mensagem: " + mensagem.trim(),
            id_tipo_tarefa: null,
            id_tipo_visita: 1
        }
        
        let telefoneComPrefixo = envolvido.celular;
        if(!PhoneHelper.verificarDDIBrasileiro(envolvido.celular)){
            telefoneComPrefixo = "+55" + envolvido.celular;
          }
        
        const urlWhatsapp = `https://wa.me/${telefoneComPrefixo}?text=${mensagemUriEnconded}`
        trackPromise(AtendimentoService.criaAtendimento(atendimento))
        window.open(urlWhatsapp, "_blank")
    }

    function defineMaskbyPhone(phone) {
        if (phone) {
            if (phone[0] === '+' && phone[1] !== '5' && phone[2] !== '5') {
                return phone;
            } else {
                return celularMask(phone);
            }
        }
    }

    const CardInfoEnvolvido = ({ envolvido }) => {
        return (
            <Card className="w-100 border shadow-sm">
                <Card.Body className="space-y-4">
                    <Card.Text>
                        <Row>
                            <Col>
                                <p className="card-text">
                                    <strong>Nome:</strong> 
                                </p>
                                {envolvido.nome}
                            </Col>
                            <Col>
                                <p className="card-text">
                                    <strong>Telefone:</strong> 
                                </p>
                                {defineMaskbyPhone(envolvido.celular)}
                            </Col>
                        </Row>
                    </Card.Text>
                </Card.Body>
            </Card>
        )
    }

    return (
        <div className="container max-w-md mx-auto p-4">
            <div className="mb-4">
                <label className="form-label">Selecione um envolvido:</label>
                {envolvidos.map((envolvido) => (
                    <div key={envolvido.id_envolvido} className="form-check d-flex align-items-center">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="phoneNumbers"
                            id={envolvido.id_envolvido}
                            value={envolvido.id_envolvido}
                            checked={envolvidoSelecionado == envolvido.id_envolvido}
                            onChange={(e) => setEnvolvidoSelecionado(e.target.value)}
                        />
                        <label className="form-check-label w-100" htmlFor={envolvido.id_envolvido}>
                            <CardInfoEnvolvido envolvido={envolvido} />
                        </label>
                    </div>
                ))}
            </div>

            <div className="mb-4">
                <label htmlFor="mensagem" className="form-label">
                    Mensagem:
                </label>
                <textarea
                    className="form-control"
                    id="mensagem"
                    rows="3"
                    placeholder="Digite sua mensagem aqui..."
                    value={mensagem}
                    onChange={(e) => setMensagem(e.target.value)}
                ></textarea>
            </div>
            <Button className="btn btn-success p-2 mx-1"  onClick={handleSend}>
                   Abrir App do WhatsApp
            </Button>
            {(configuracoesMeioComunicacao?.whatsapp && oportunidadeSelecionada?.usuario_pode_editar && hasConecta) ? (
                <Button className="btn btn-primary p-2 mx-1"   onClick={() => verificarConfiguracaoMeioComunicacao('whatsapp', oportunidadeSelecionada.id_oportunidade)}>
                   Abrir Central de Atendimento Dommus
                </Button>
            ) : <></>}
        </div>
    )
}
