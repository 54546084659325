import React, { createContext, Dispatch, ReactElement, SetStateAction, useState } from "react";
import { AnuncioAssinado } from "./types";

interface TikTokProps {
    tiktokHabilitado:  boolean;
    setTiktokHabilitado: Dispatch<SetStateAction<boolean>>;
    tiktokAdvertiserAccessToken: boolean;
    setTiktokAdvertiserAccessToken: Dispatch<SetStateAction<boolean>>;
    tiktokAdvertiserId: string | null;
    settiktokAdvertiserId: Dispatch<SetStateAction<string | null>>;
    openToggleAccessToken: boolean
    setOpenToggleAccessToken: Dispatch<SetStateAction<boolean>>;
    openToggleAdicionarAnuncios: boolean
    setOpenToggleAdicionarAnuncios: Dispatch<SetStateAction<boolean>>;
    listaAnunciosAssinados: AnuncioAssinado[];
    setListaAnunciosAssinados: Dispatch<SetStateAction<AnuncioAssinado[]>>;
}

export const TikTokContext = createContext({} as TikTokProps);

export function TikTokProvider({ children}: { children: ReactElement }) {
    const [listaAnunciosAssinados, setListaAnunciosAssinados] = useState<AnuncioAssinado[]>([] as AnuncioAssinado[])
    const [tiktokHabilitado, setTiktokHabilitado] = useState(false);
    const [tiktokAdvertiserAccessToken, setTiktokAdvertiserAccessToken] = useState(false);
    const [tiktokAdvertiserId, settiktokAdvertiserId] = useState<string | null>(null);
    const [openToggleAccessToken, setOpenToggleAccessToken] = useState(false);
    const [openToggleAdicionarAnuncios, setOpenToggleAdicionarAnuncios] = useState(false);
    

    return (
        <TikTokContext.Provider 
            value={{
                tiktokHabilitado,
                setTiktokHabilitado,
                tiktokAdvertiserAccessToken,
                setTiktokAdvertiserAccessToken,
                tiktokAdvertiserId,
                settiktokAdvertiserId,
                openToggleAccessToken,
                setOpenToggleAccessToken,
                openToggleAdicionarAnuncios,
                setOpenToggleAdicionarAnuncios,
                listaAnunciosAssinados,
                setListaAnunciosAssinados,
            }}>
        {children}
        </TikTokContext.Provider>
    );
}