import React, { useState, useEffect, useRef, useContext } from "react";
import {  DropdownButton,Dropdown, OverlayTrigger, Tooltip, Alert, Col, Tabs } from "react-bootstrap";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEdit, faPlus, faExternalLinkAlt, faPlusCircle, faRightLeft, faEllipsisVertical, faCopy, faSignIn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalDommus from "../../../components/Modal";
import "./style.css";
import DommusToggle from "../../../components/DommusToggle";
import ReactTooltip from "react-tooltip";
import NovoCadastro from "./NovoCadastro";
import { celularMask } from "../../../components/InputTextMask";
import { trackPromise } from "react-promise-tracker";
import OportunidadeService from "../../../services/OportunidadeService";
import ConfiguracoesGestaoService from "../../../services/ConfiguracoesGestaoService";
import guService from "../../../services/GuService";
import Atendimento from "../Atendimento";
import Pendencias from "../Pendencias";
import Anexos from "../Anexos";
import Swal from "sweetalert2";
import { DistributionForm } from "./DistributionForm";
import moment from "moment";
import HistoricoLead from "../../../components/HistoricoLead";
import InformacoesAdicionais from "../../Leads/InformacoesAdicionais";
import EditarLead from "./EditarLead";
import NovoLeadOPV from './AddNovoLead'
import oportunidadeService from "../../../services/OportunidadeService";
import helper from "../../../helpers/format";
import DommusCarouselInteresse from "../../../components/DommusCarouselInteresse";
import { EnvolvidoType, InteresseType, LeadOportunidadeType, TipoAtendimentoType, OportunidadeType, HistoricoLeadType } from "./types";
import BibliotecaIcones from "../../../components/BibliotecaIcones";
import MudarParaOutraOPV from "./MudarParaOutraOPV";
import stringHelper from "../../../helpers/StringHelper";
import { toastErro, toastSucesso } from "../../../helpers/DommusSwal";
import NovoAtendimento from "../Atendimento/NovoAtendimento";
import NovoAnexo from "../Anexos/NovoAnexo";
import NovaPendencia from "../Pendencias/NovaPendencia";
import perfilUsuarioService from "../../../services/PerfilUsuarioService";
import InteresseCadastroView from "../../../components/InteresseCadastroView";
import { DetalhesOportunidadeContext } from "../../../contexts/DetalhesOportunidadeContext/DetalhesOportunidadeContext";
import { TagsOportunidade } from "../../../components/TagsOportunidade";
import { TagOportunidadeFormulario } from "../../../components/TagsFormulario";
import { OportunidadePorStatusPaginadoType } from "../../Oportunidades/Visualizacao/FunilOportunidades/types";
import { DommusTab } from "../../../components/DommusTab";
import { isDesktop, isTablet, isMobile } from "react-device-detect";
import { OportunidadeContext } from "../../../components/GlobalContext/globalContext";

interface CadastroProps {
  listaLeads: Array<LeadOportunidadeType>
  dados: Array<EnvolvidoType>
  oportunidade: OportunidadeType
  interesses: InteresseType
  dadosOportunidade: OportunidadeType
  atendimentos: Array<object>
  anexos: Array<object>
  pendencias: Array<object>
  setTabIndex: (val: number) => void
  setRefreshEnvolvido: (val: boolean) => void
  refreshEnvolvido: boolean
  atualizarOportunidade: (oportunidade:(OportunidadeType | OportunidadePorStatusPaginadoType)) => void
}

export default function Cadastro({
  listaLeads,
  dados,
  oportunidade,
  interesses,
  atendimentos,
  dadosOportunidade,
  anexos,
  pendencias,
  setTabIndex,
  setRefreshEnvolvido,
  refreshEnvolvido,
  atualizarOportunidade,
}: CadastroProps) {
  const [leads, setLeads] = useState([] as Array<LeadOportunidadeType>);
  const [clientes, setClientes] = useState([] as Array<EnvolvidoType>);
  const [openNovoCadastro, setOpenNovoCadastro] = useState(false);
  const [envolvido, setEnvolvido] = useState({} as EnvolvidoType);
  const [dadosLead, setDadosLead] = useState({} as LeadOportunidadeType);
  const [editarLead, setEditarLead] = useState(false);


  const [openModalMobile, setopenModalMobile] = useState(false);
  const [historico, setHistorico] = useState<HistoricoLeadType[]>([] as HistoricoLeadType[]);
  const [leadSelecionado, setLeadSelecionado] = useState(0);

  const [openModalEditarLead, setOpenModalEditarLead] = useState(false);
  const [openModalMudarOPV, setOpenModalMudarOPV] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [openModalNvLead, setopenModalNvLead] = useState(false)

  const [tiposAtendimentos, setTiposAtendimentos] = useState([])
  const [tipo, setTipo] = useState(0)

  const refComentario = useRef<HTMLSpanElement>(null);

  const [openModalAtendimento, setOpenModalAtendimento] = useState(false);
  const [openModalAnexo, setOpenModalAnexo] = useState(false);
  const [openModalPendencia, setOpenModalPendencia] = useState(false);
  const { permissaoEditarLead, setPermissaoEditarLead } = useContext(DetalhesOportunidadeContext)
  const [visualizacaoHistoricoUnificado, setVisualizacaoHistoricoUnificado] = useState((guService.getLocalState('visualizar_historico_unificado') === 'true'));
  const [openModalTagsOportunidade,setOpenModalTagsOportunidade] = useState<boolean>(false);
  const exibirCanalOpv = JSON.parse(guService.getLocalState('exibirCanalOpv') || "false") ;

  function editEnvolvido(envolvidoselecionado: EnvolvidoType) {
    setEnvolvido(envolvidoselecionado);
    setOpenNovoCadastro(true);
  }

  function atualizarEnvolvidos(atualiza = false) {
    trackPromise(
      OportunidadeService.buscarListaEnvolvidos(oportunidade.id_oportunidade).then(
        (response) => {
          const clientesAtualizada = response.data.slice();
          setClientes(clientesAtualizada);
          setRefreshEnvolvido(!refreshEnvolvido)
        },
        (error) => {
          Swal.fire({
            titleText: "Ooops...",
            text: "Ocorreu um erro ao buscar os envolvidos: " + error,
            icon: "error",
          });
        }
      )
    );
  }

  useEffect(() => {
    if(!(permissaoEditarLead === null)) {
      setEditarLead(permissaoEditarLead);
      return
    }
    ConfiguracoesGestaoService.buscarConfiguracao("permissao_editar_lead")
      .then((response) => {
        if(response.data !== undefined) {
          setPermissaoEditarLead(response.data.includes(Number(guService.getLocalState("tipoUsuario"))))
          const buscaconfig = Object.values(response.data);
          if (
            Object.values(buscaconfig).includes(
              Number(guService.getLocalState("tipoUsuario"))
            )
          ) {
            setEditarLead(true);
          } else {
            setEditarLead(false);
          }
        }
      }).catch((error) =>
        Swal.fire({
          titleText: "Ooops...",
          text: "Ocorreu um erro ao buscar as permissões: " + error,
          icon: "error",
        })
      )
  }, []);

  //carrega lista de leads
  useEffect(() => {
    const listaAtualizada = listaLeads.slice();
    setLeads(listaAtualizada);
    setLeadSelecionado(listaAtualizada[0].id_lead);
  }, [listaLeads, refresh]);

  //carrega lista de clientes / envolvidos
  useEffect(() => {
    const clientesAtualizada = dados.slice();
    setClientes(clientesAtualizada);
  }, [dados, refresh]);

  const handleChangeLeadSelecionado = (lead:number)=>{
    setLeadSelecionado(lead);
  }

  const handleVisualizarHistoricoUnificado = (value:boolean)=>{
    let dados = {
      id_usuario: guService.getLocalState('idUsuario'),
      perfil: {
        visualizar_historicos_oportunidade_unificado: value
      }
    }
    perfilUsuarioService.atualizarConfiguracao(dados).then((response)=>{
      guService.setLocalState('visualizar_historico_unificado', value);
      setVisualizacaoHistoricoUnificado(value);
    }).catch((error)=>{
      Swal.fire({
        titleText: "Ooops...",
        text: "Ocorreu um erro ao alterar visualização de histórico: " + error,
        icon: "error",
      })
    })
  }

  function abrirCadastro() {
    setEnvolvido({} as EnvolvidoType);
    setOpenNovoCadastro(true);
  }

  function atendimentoAutomatico(tipoA: number, celular: string | null){
        var dados = {
          titulo: "",
          data: new Date(),
          data_inicial: helper.formataMysqlDateHour(""),
          data_final: helper.formataMysqlDateHour(""),
          id_tipo_tarefa: null,
          id_tipo_atendimento: tipoA,
          contato: celular,
          observacao: "",
          hora: moment(new Date()).format("HH:MM:ss"),
          feedback: "",
          local: "",
          id_oportunidade: oportunidade.id_oportunidade,
      };

      Swal.fire({
          title: 'Deseja adicionar um novo atendimento?',
          text: "Será criado um atendimento do tipo mensagem (WhatsApp).",
          icon: 'question',
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não.',
          customClass: {
              confirmButton: 'btn btn-primary mr-3',
              cancelButton: 'btn btn-danger'
          },
          buttonsStyling: false
      }).then((result) => {
          if (result.isConfirmed) {

              trackPromise(
                      oportunidadeService.salvarAtendimento(dados)
                  ).then(response => {
                  Swal.fire({
                      position: 'center',
                      icon: "success",
                      title: 'Atendimento registrado com sucesso.',
                      showConfirmButton: false,
                      timer: 2000
                  })
              }).catch(error => {
                  Swal.fire(
                      'Ooops...',
                      'Ocorreu um erro ao salvar o atendimento: ' + error,
                      'error'
                  )
              })
          }
      })
  }

  function cadastrarAtendimento(celular: string | null){
    oportunidadeService.buscarTiposAtendimentos().then(
        (response) => {
            if (response.data !== "") {
                setTiposAtendimentos(response.data);
                let tipoA = 0
                response.data.map((tipo: TipoAtendimentoType) => (
                  tipo.nome == "Mensagem" ?
                  (
                    tipoA = tipo.id_tipo_atendimento,
                    setTipo(tipo.id_tipo_atendimento)
                  ) : <></>
                ))

                atendimentoAutomatico(tipoA, celular)
            }
        },
        (error) => {

            Swal.fire({
            titleText: "Ooops...",
            text: "Ocorreu um erro ao buscar a lista de tipos: " + error.data,
            icon: "error",
            });
        }
    )

  }

  function formatarNumero(telefone: String | null) {
    if (telefone) {
      if (telefone[0] === '+') {
        return telefone;
      } else if (telefone.length >= 12) {
        return '+' + telefone;
      } else if (telefone[0] === '5' && telefone[1] === '5') {
        return '+' + telefone;
      } else {
        return '+55' + telefone;
      }
    } else {
      return "";
    }
  }


  function handleCriarComentario(){
    let comentario = refComentario?.current?.innerText;
    let dados = {
      titulo: "",
      id_tipo_tarefa: null,
      id_tipo_atendimento: 7,
      id_local: null,
      contato: null,
      observacao: comentario,
      feedback: "",
      local: "",
      id_oportunidade: oportunidade.id_oportunidade,
      responsavel: null,
    }
    if(comentario?.length){
      trackPromise(OportunidadeService.salvarAtendimento(dados)).then((response)=>{
        toastSucesso("Comentário registrado com sucesso!");
        if(refComentario.current){
          refComentario.current.textContent = "";
        }
        setRefresh((current)=>!current);
      }).catch((error)=>{
        toastErro("Erro ao registrar comentário")
      });
    }
  }


  function defineMaskbyPhone(phone:string){
    if(phone){
      if(phone[0] === '+' && phone[1] !== '5' && phone[2] !== '5'){
        return phone;
      }else{
        return celularMask(phone);
      }
    }
  }


  const componenteLeadMobile = (
    <div className={`opv-lead-mobile ${isTablet ? "opv-lead-tablet" : ""}`}>
      <Tabs >
        <DommusTab eventKey="leads" title="Leads">
          <div className="leads-wrapper">
            <div className="leads-mobile-card">
              <ul
                className="nav nav-pills leads-navigation"
                id="pills-tab"
                role="tablist"
              >
                {leads.map((item) => {
                  return (
                    <li key={item.id_lead} className="nav-item row">
                      <Col className="coluna-lead" xs={12} style={{margin: "0"}}>
                      <a
                        className={`nav-link  ${
                          leadSelecionado === item.id_lead ? "active" : ""
                        } ` }
                        id="pills-home-tab"
                        data-toggle="pill"
                        href="#"
                        onClick={() => {
                          handleChangeLeadSelecionado(item.id_lead);
                          // if (
                          //   window.matchMedia("(max-width: 767px)").matches
                          // ) {
                          //   setopenModalMobile(true);
                          // }
                        }}
                        role="tab"
                        aria-controls="pills-home"
                        aria-orientation="vertical"
                      >
                        <div className="midiaInfo">

                          <ReactTooltip />
                          {item.lead && item.lead.midia && (
                            <div className="midia" data-tip={item.lead.midia.descricao}>
                              <BibliotecaIcones optionsSelectComIcones={''} setIdSelect={''} chaveLabelSelect={''}
                                chaveIdIconeSelecionado={''} tipoExibicao={'icone'} chaveIcone={item.lead.midia.icone}
                                sizeIcone={''} colorIcone={item.lead.midia.cor} value={''} setValue={''} estilo={''} iconeSelecionado={''} />
                            </div>
                          )}

                          <div className="infos">
                            <div
                              title={
                                item?.lead?.nome && item?.lead?.nome.length > 36
                                ? item?.lead?.nome : ''}
                              className="info nome"
                              style={{ zIndex: 100 }}
                            >
                              <span>Nome:</span> {item?.lead?.nome} &nbsp;

                            </div>

                            <div
                            title={
                              item?.lead?.email && item?.lead?.email.length > 20
                              ? item?.lead?.email : ''}
                            className="info email"
                            >
                              <span>Email:</span> <div className="email-dado">{item?.lead?.email}</div>
                              {item?.lead?.email &&
                                <OverlayTrigger
                                    key="iconCopy"
                                    placement="top"
                                    overlay={
                                      <Tooltip id={String(item?.lead?.email)}>
                                      <>Copiar</>
                                    </Tooltip>
                                    }
                                >
                                  <FontAwesomeIcon color={'#666'} style={{marginLeft: (isMobile ? '5px' : '10px'), marginTop: '3px', cursor:"pointer"}}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if(item?.lead?.email) {
                                        stringHelper.copyBoard(item?.lead?.email)
                                      }
                                    }} icon={faCopy} />
                                </OverlayTrigger>
                              }
                            </div>
                            <div className="info telefone" style={{minWidth: '300px'}}>
                              <span>Telefone:</span>
                              <ul style={{listStyle: 'none'}}>
                                  <li>
                                    {
                                      item?.lead?.telefone == null || item?.lead?.telefone == "" ?
                                      <></> :
                                      <OverlayTrigger
                                        key="iconCopy"
                                        placement="top"
                                        overlay={
                                        <Tooltip id={item.oportunidade ? String(item.oportunidade.id_oportunidade) : '0'}>
                                          Contactar pelo WhatsApp
                                        </Tooltip>
                                        }
                                      >
                                        <div className="d-flex d-flex-row align-items-center">
                                          <a className="d-flex d-flex-row align-items-center" onClick={() => {cadastrarAtendimento(item?.lead?.telefone || "")}} style={{color: 'green', padding: 0, fontSize: '11px'}} target="_blank" rel="noreferrer" href={`https://wa.me/${formatarNumero(item?.lead?.telefone || "")}`}>
                                            <FontAwesomeIcon icon={faWhatsapp} style={{fontSize:"14px"}}/>
                                            <div>{defineMaskbyPhone(item?.lead?.telefone)}</div>
                                          </a>
                                          {item?.lead?.telefone &&
                                            <OverlayTrigger
                                                key="iconCopy"
                                                placement="top"
                                                overlay={
                                                <Tooltip id={String(item?.lead?.email)}>
                                                  <>Copiar</>
                                                </Tooltip>
                                                }
                                            >
                                              <FontAwesomeIcon color={'#666'} style={{marginLeft: (isMobile ? '5px' : '10px'), cursor:"pointer"}}
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  if(item?.lead?.telefone) {
                                                    stringHelper.copyBoard(defineMaskbyPhone(item?.lead?.telefone))
                                                  }
                                                }} icon={faCopy} />
                                            </OverlayTrigger>
                                          }
                                        </div>
                                      </OverlayTrigger>
                                    }
                                  </li>
                              </ul>
                            </div>
                            <div title={
                              item?.lead?.nome_empreendimento && item?.lead?.nome_empreendimento.length > 20
                              ? item?.lead?.nome_empreendimento : ''}
                              className="info empreendimento"
                            >
                              {item?.lead?.nome_empreendimento && (
                                <>
                                  <span>Empreendimento:</span>{" "}
                                  {item?.lead?.nome_empreendimento ?? ""}
                                </>
                              )}
                            </div>
                            <div
                              className="info campanha"
                              title={
                                item?.lead?.campanha && item?.lead?.campanha.nome.length > 35
                                ? item?.lead?.campanha.nome : ''}
                            >
                              {item?.lead?.campanha && (
                                <>
                                  <span>Campanha:</span>{" "}
                                  {item?.lead?.campanha.nome ?? ""}
                                </>
                              )}
                            </div>
                            <div
                              className="info campanha"
                              title={
                                item?.lead?.origem?.origem && item?.lead?.origem?.origem.length > 35 ? `${item?.lead?.origem?.origem.substring(0, 33)}...` :
                                  item?.lead?.origem?.origem || ''
                              }
                            >
                              {item?.lead?.origem?.origem && (
                                <>
                                  <span>Origem:</span>{item?.lead?.origem?.origem || ""} &nbsp;
                                </>
                              )}
                            </div>
                            {
                              exibirCanalOpv && <div
                                className="info campanha"
                                title={item?.lead?.canal?.nome && item.lead.canal.nome.length > 35 ? `${item.lead.canal.nome.substring(0, 33)}...` : item.lead?.canal?.nome || ''}
                              >
                                <>
                                  <span>Entrada do Lead:</span>{item?.lead?.canal?.nome || 'Não Informado'} &nbsp;
                                  {item.lead?.informacoes_adicionais && (<InformacoesAdicionais informacoesAdicionais={item.lead.informacoes_adicionais}/>)}
                                </>
                              </div>

                            }

                            <div className="data-item-mobile">
                              <div className="info data">
                                <span>Cadastrado em: </span>
                                {item.lead &&
                                  `${moment(item.lead.criado_em).format('DD/MM/YYYY')} às ${moment(item.lead.criado_em).format('HH:mm')}` }
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                      <Col xs={0.5} className="info-adicionais-lead">
                        {item.lead?.informacoes_adicionais && (<InformacoesAdicionais informacoesAdicionais={item.lead.informacoes_adicionais}/>)}
                      </Col>
                      {( editarLead || leads.length > 1) &&
                          <>
                            {oportunidade?.usuario_pode_editar &&
                            <Col  xs={0.5} className="item-edicao-lead">
                              <DropdownButton id="dropdownMenu" style={{boxShadow: "none"}}
                                title={<FontAwesomeIcon icon={faEllipsisVertical} />}
                              >
                                {editarLead &&
                                <>
                                <Dropdown.Item className="opcaoMenu" onClick={() => {
                                  setOpenModalEditarLead(true);
                                  setDadosLead(item);
                                }}>
                                  <div className="opcao-editar">
                                    <FontAwesomeIcon icon={faEdit} color="#12a2b8" />
                                    <span className="tituloArquivar"> Editar Lead </span>
                                  </div>
                                </Dropdown.Item>
                                </>
                                }
                                  {leads.length > 1 && <>
                                <Dropdown.Item className="opcaoMenu" onClick={() => {
                                  setOpenModalMudarOPV(true);
                                  setDadosLead(item);
                                }}>
                                  <div className="opcao-remanejar">
                                    <FontAwesomeIcon icon={faRightLeft} color="#12a2b8" />
                                    <span className="tituloExcluir"> Remanejar Lead</span>
                                  </div>
                                </Dropdown.Item>
                                </>}
                              </DropdownButton>
                            </Col>
                            }
                          </>
                        }
                      </Col>
                    </li>

                  );
                })}
              </ul>
              { oportunidade.leads_oportunidade.length > 1 &&
                <div className="unificar-historico-leads-section">
                  <input type="checkbox" id="unificar-historico-leads"
                          checked={guService.getLocalState('visualizar_historico_unificado') === 'true'}
                          onChange={(e)=>{
                            handleVisualizarHistoricoUnificado(e.target.checked);
                          }}/>
                  <label htmlFor="unificar-historico-leads">Visualizar históricos unificados</label>
                </div>
              }
              <DistributionForm id_oportunidade={oportunidade} permissao_editar={oportunidade?.usuario_pode_editar}/>
            </div>
          </div>
        </DommusTab>
        <DommusTab eventKey="historico" title="Historicos">
          <HistoricoLead idLead={leadSelecionado} idOportunidade={oportunidade.id_oportunidade}/>
        </DommusTab>
      </Tabs>
    </div>
  )

  const componenteLeadDesktop = (
    <div className="leads-wrapper">
      <div>
        <ul
          className="nav nav-pills leads-navigation"
          id="pills-tab"
          role="tablist"
        >
          {leads.map((item) => {
            return (
              <li key={item.id_lead} className="nav-item row">
                <Col className="coluna-lead" xs={12} style={{margin: "0"}}>
                <a
                  className={`nav-link  ${
                    leadSelecionado === item.id_lead ? "active" : ""
                  } ` }
                  id="pills-home-tab"
                  data-toggle="pill"
                  href="#"
                  onClick={() => {
                    handleChangeLeadSelecionado(item.id_lead);
                    if (
                      window.matchMedia("(max-width: 767px)").matches
                    ) {
                      setopenModalMobile(true);
                    }
                  }}
                  role="tab"
                  aria-controls="pills-home"
                  aria-orientation="vertical"
                >
                  <div className="midiaInfo">

                    <ReactTooltip />
                    {item.lead && item.lead.midia && (
                      <div className="midia midia-detalhes" data-tip={item.lead.midia.descricao}>
                        <BibliotecaIcones optionsSelectComIcones={''} setIdSelect={''} chaveLabelSelect={''}
                          chaveIdIconeSelecionado={''} tipoExibicao={'icone'} chaveIcone={item.lead.midia.icone}
                          sizeIcone={''} colorIcone={item.lead.midia.cor} value={''} setValue={''} estilo={''} iconeSelecionado={''} />
                      </div>
                    )}

                    <div className="infos">
                      <div
                        title={
                          item?.lead?.nome && item?.lead?.nome.length > 36
                          ? item?.lead?.nome : ''}
                        className="info nome"
                        style={{ zIndex: 100 }}
                      >
                        <span>Nome:</span> {item?.lead?.nome} &nbsp;

                      </div>

                      <div
                      title={
                        item?.lead?.email && item?.lead?.email.length > 20
                        ? item?.lead?.email : ''}
                      className="info email"
                      >
                        <span>Email:</span> <div className="email-dado">{item?.lead?.email}</div>
                        {item?.lead?.email &&
                          <OverlayTrigger
                              key="iconCopy"
                              placement="top"
                              overlay={
                                <Tooltip id={String(item?.lead?.email)}>
                                <>Copiar</>
                              </Tooltip>
                              }
                          >
                            <FontAwesomeIcon color={'#666'} style={{marginLeft: (isMobile ? '5px' : '10px'), marginTop: '3px', cursor:"pointer"}}
                              onClick={(e) => {
                                e.stopPropagation();
                                if(item?.lead?.email) {
                                  stringHelper.copyBoard(item?.lead?.email)
                                }
                              }} icon={faCopy} />
                          </OverlayTrigger>
                        }
                      </div>
                      <div className="info telefone">
                        <span>Telefone:</span>
                        <ul style={{listStyle: 'none'}}>
                            <li>
                              {
                                item?.lead?.telefone == null || item?.lead?.telefone == "" ?
                                <></> :
                                <OverlayTrigger
                                  key="iconCopy"
                                  placement="top"
                                  overlay={
                                  <Tooltip id={item.oportunidade ? String(item.oportunidade.id_oportunidade) : '0'}>
                                    Contactar pelo WhatsApp
                                  </Tooltip>
                                  }
                                >
                                  <div className="d-flex d-flex-row align-items-center">
                                    <a className="d-flex d-flex-row align-items-center" onClick={() => {cadastrarAtendimento(item?.lead?.telefone || "")}} style={{color: 'green', padding: 0, fontSize: '11px'}} target="_blank" rel="noreferrer" href={`https://wa.me/${formatarNumero(item?.lead?.telefone || "")}`}>
                                      <FontAwesomeIcon icon={faWhatsapp} style={{fontSize:"14px"}}/>
                                      <div>{defineMaskbyPhone(item?.lead?.telefone)}</div>
                                    </a>
                                    {item?.lead?.telefone &&
                                      <OverlayTrigger
                                          key="iconCopy"
                                          placement="top"
                                          overlay={
                                          <Tooltip id={String(item?.lead?.email)}>
                                            <>Copiar</>
                                          </Tooltip>
                                          }
                                      >
                                        <FontAwesomeIcon color={'#666'} style={{marginLeft: (isMobile ? '5px' : '10px'), cursor:"pointer"}}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            if(item?.lead?.telefone) {
                                              stringHelper.copyBoard(defineMaskbyPhone(item?.lead?.telefone))
                                            }
                                          }} icon={faCopy} />
                                      </OverlayTrigger>
                                    }
                                  </div>
                                </OverlayTrigger>
                              }
                            </li>
                        </ul>
                      </div>
                      <div title={
                        item?.lead?.nome_empreendimento && item?.lead?.nome_empreendimento.length > 20
                        ? item?.lead?.nome_empreendimento : ''}
                        className="info empreendimento"
                      >
                        {item?.lead?.nome_empreendimento && (
                          <>
                            <span>Empreendimento:</span>{" "}
                            {item?.lead?.nome_empreendimento ?? ""}
                          </>
                        )}
                      </div>
                      <div
                        className="info campanha"
                        title={
                          item?.lead?.campanha && item?.lead?.campanha.nome.length > 35
                          ? item?.lead?.campanha.nome : ''}
                      >
                        {item?.lead?.campanha && (
                          <>
                            <span>Campanha:</span>{" "}
                            {item?.lead?.campanha.nome ?? ""}
                          </>
                        )}
                      </div>
                      <div
                        className="info campanha"
                        title={
                          item?.lead?.origem?.origem && item?.lead?.origem?.origem.length > 35 ? `${item?.lead?.origem?.origem.substring(0, 33)}...` :
                            item?.lead?.origem?.origem || ''
                        }
                      >
                        {item?.lead?.origem?.origem && (
                          <>
                            <span>Origem:</span>{item?.lead?.origem?.origem || ""} &nbsp;
                          </>
                        )}
                      </div>
                      {
                        exibirCanalOpv && <div
                          className="info campanha"
                          title={item?.lead?.canal?.nome && item.lead.canal.nome.length > 35 ? `${item.lead.canal.nome.substring(0, 33)}...` : item.lead?.canal?.nome || ''}
                        >
                          <span>Entrada do Lead:</span>{item?.lead?.canal?.nome || 'Não Informado'} &nbsp;
                        </div>
                      }
                      {item?.lead?.criado_em && (<div className="data-cadastro-lead text-muted">
                        <em><span> Entrada:</span>{`${moment(item.lead.criado_em).format('DD/MM/YYYY')} às ${moment(item.lead.criado_em).format('HH:mm')}`}</em>
                      </div>)}
                    </div>
                  </div>
                </a>
                <Col xs={0.5} className="info-adicionais-lead">
                  {item.lead?.informacoes_adicionais && (<InformacoesAdicionais informacoesAdicionais={item.lead.informacoes_adicionais}/>)}
                </Col>
                {( editarLead || leads.length > 1) &&
                    <>
                      {oportunidade?.usuario_pode_editar &&
                      <Col  xs={0.5} className="item-edicao-lead">
                        <DropdownButton id="dropdownMenu" style={{boxShadow: "none"}}
                          title={<FontAwesomeIcon icon={faEllipsisVertical} />}
                        >
                          {editarLead &&
                          <>
                          <Dropdown.Item className="opcaoMenu" onClick={() => {
                            setOpenModalEditarLead(true);
                            setDadosLead(item);
                          }}>
                            <div className="opcao-editar">
                              <FontAwesomeIcon icon={faEdit} color="#12a2b8" />
                              <span className="tituloArquivar"> Editar Lead </span>
                            </div>
                          </Dropdown.Item>
                          </>
                          }
                            {leads.length > 1 && <>
                          <Dropdown.Item className="opcaoMenu" onClick={() => {
                            setOpenModalMudarOPV(true);
                            setDadosLead(item);
                          }}>
                            <div className="opcao-remanejar">
                              <FontAwesomeIcon icon={faRightLeft} color="#12a2b8" />
                              <span className="tituloExcluir"> Remanejar Lead</span>
                            </div>
                          </Dropdown.Item>
                          </>}
                        </DropdownButton>
                      </Col>
                      }
                    </>
                  }
                </Col>
              </li>

            );
          })}
        </ul>
        { oportunidade.leads_oportunidade.length > 1 &&
          <div className="unificar-historico-leads-section">
            <input type="checkbox" id="unificar-historico-leads"
                    checked={guService.getLocalState('visualizar_historico_unificado') === 'true'}
                    onChange={(e)=>{
                      handleVisualizarHistoricoUnificado(e.target.checked);
                    }}/>
            <label htmlFor="unificar-historico-leads">Visualizar históricos unificados</label>
          </div>
        }
        <DistributionForm id_oportunidade={oportunidade} permissao_editar={oportunidade?.usuario_pode_editar}/>
      </div>
        {/* <HistoricoLead historico={historico} carregarMaisHistorico={handleCarregarHistorico} hasMore={hasMoreHistorico}/> */}
        <HistoricoLead idLead={leadSelecionado} idOportunidade={oportunidade.id_oportunidade}/>
    </div>
  );

  const itensDesktop = (
    <div className="informacoes-lead-wrapper">
      <div className={`leads ${isTablet ? "leads-tablet" : ""}`}>
        <DommusToggle altura={'altura'} title={["Informações do(s) Lead(s)",
      ]}
      icones={oportunidade.usuario_pode_editar &&
        <div key={"infoLeads"} className="styleIconNvLead" onClick={() => {setopenModalNvLead(!openModalNvLead)}} data-tip="Novo Lead">
          <FontAwesomeIcon size={'2x'} color="#12a2b8" icon={faPlusCircle}/>
        </div>}
      toggle={true}>
        {isTablet ?
          componenteLeadMobile
          :
          componenteLeadDesktop
        }
        </DommusToggle>
        <div className="toggleCadastro">
          <DommusToggle
            title={
              <a
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setTabIndex(2);
                }}
              >
                Atendimento{" "}
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  style={{ marginLeft: 5 }}
                />
              </a>
            }
            toggle={true}
          >
            <Atendimento atualizarEnvolvidos={atualizarEnvolvidos}  history={{}} oportunidade={oportunidade} dados={atendimentos} setTabIndex={undefined} atualizarOportunidade={setRefreshEnvolvido}/>
          </DommusToggle>
        </div>
        <div className="toggleCadastro">
          <DommusToggle
            title={
              <a
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setTabIndex(4);
                }}
              >
                Pendências{" "}
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  style={{ marginLeft: 5 }}
                />
              </a>
            }
            toggle={true}
          >
            <Pendencias history={{}} oportunidade={oportunidade} dados={pendencias} />
          </DommusToggle>
        </div>
        <div className="toggleCadastro">
          <DommusToggle
            title={
              <a
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setTabIndex(3);
                }}
              >
                Anexos{" "}
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  style={{ marginLeft: 5 }}
                />
              </a>
            }
            toggle={true}
          >
            <Anexos history={{}} oportunidade={oportunidade} dados={anexos} />
          </DommusToggle>
        </div>
      </div>
      <div className="pessoas-envolvidas">
        {oportunidade?.usuario_pode_editar &&
          <div className="comentario-field-section">
            <span ref={refComentario} className="comentario-field" role="textarea" contentEditable/>
            <OverlayTrigger
                  key="registroComentario"
                  placement="top"
                  overlay={
                  <Tooltip id={"registroComentario"}>
                    Registrar comentário
                  </Tooltip>
                  }
              >
              <button className="btn-comentario-field" onClick={()=>handleCriarComentario()}>
                <FontAwesomeIcon icon={faSignIn}/>
              </button>
            </OverlayTrigger>
          </div>
        }
        <fieldset className="cadastro-tags-section">
          <legend>Etiquetas</legend>
          <div className="tags-space">
            {oportunidade.tags_oportunidade?.length ?
            <TagsOportunidade
              tags={oportunidade.tags_oportunidade ?? []}
              maxTags={10}
              useEllipse={false}
            />
            :
            <Alert className="alert-etiquetas" variant="warning">Ainda não possui nenhuma etiqueta!</Alert>
            }
          </div>
          <button className="button-tags"
            onClick={()=>{
              setOpenModalTagsOportunidade(true);
            }}
          >
            <FontAwesomeIcon icon={faEdit}/>
          </button>
        </fieldset>
        <DommusToggle title="Cliente(s) Envolvido(s)" toggle={true}>
          <div className="pessoas-wrapper">
            <div className="slick-slider pessoa-carousel">
              <DommusCarouselInteresse 
                clientes={clientes} 
                editEnvolvido={editEnvolvido} 
                atualizarEnvolvidos={atualizarEnvolvidos} 
                cadastrarAtendimento={cadastrarAtendimento} 
                permissaoEditar={oportunidade?.usuario_pode_editar} 
                idOportunidade={dadosOportunidade.id_oportunidade} 
                oportunidade={dadosOportunidade}
              />
            </div>
          </div>
        </DommusToggle>
        <DommusToggle title="Qualificação" toggle={true}>
          <InteresseCadastroView interesse={interesses} empreendimento={dadosOportunidade.id_empreendimento}/>
        </DommusToggle>
      </div>
    </div>
  );

  const itensMobile = (
    <div className="informacoes-lead-wrapper">
      {oportunidade?.usuario_pode_editar &&
        <div className="comentario-field-section">
          <span ref={refComentario} className="comentario-field" role="textarea" contentEditable/>
          <OverlayTrigger
                key="registroComentario"
                placement="top"
                overlay={
                <Tooltip id={"registroComentario"}>
                  Registrar comentário
                </Tooltip>
                }
            >
            <button className="btn-comentario-field" onClick={()=>handleCriarComentario()}>
              <FontAwesomeIcon icon={faSignIn}/>
            </button>
          </OverlayTrigger>
        </div>
      }
      <fieldset className="cadastro-tags-section">
        <legend>Etiquetas</legend>
        <div className="tags-space">
          {oportunidade.tags_oportunidade?.length ?
          <TagsOportunidade
            tags={oportunidade.tags_oportunidade ?? []}
            maxTags={10}
            useEllipse={false}
          />
          :
          <Alert className="alert-etiquetas" variant="warning">Ainda não possui nenhuma etiqueta!</Alert>
          }
        </div>
        <button className="button-tags"
          onClick={()=>{
            setOpenModalTagsOportunidade(true);
          }}
        >
          <FontAwesomeIcon icon={faEdit}/>
        </button>
      </fieldset>
      <div className="pessoas-envolvidas">
        <DommusToggle title="Cliente(s) Envolvido(s)" toggle={false}>
          <div className="pessoas-wrapper">
            <div className="slick-slider pessoa-carousel">
              <DommusCarouselInteresse 
                clientes={clientes} 
                editEnvolvido={editEnvolvido} 
                atualizarEnvolvidos={atualizarEnvolvidos} 
                cadastrarAtendimento={cadastrarAtendimento} 
                permissaoEditar={oportunidade?.usuario_pode_editar} 
                idOportunidade={dadosOportunidade.id_oportunidade} 
                oportunidade={dadosOportunidade}
              />
            </div>
          </div>
        </DommusToggle>
      </div>
      <div className="leads">
        <DommusToggle altura={'altura'} title={["Informações do(s) Lead(s)",]}
        icones={
          oportunidade.usuario_pode_editar &&
          <div key={"infoLeads"} className="styleIconNvLead" onClick={() => {setopenModalNvLead(!openModalNvLead)}} data-tip="Novo Lead">
            <FontAwesomeIcon size={'2x'} color="#12a2b8" icon={faPlusCircle}/>
          </div>
        }
        toggle={false}>
        {componenteLeadMobile}
        </DommusToggle>
        <div className="pessoas-envolvidas">
          <DommusToggle title="Qualificação" toggle={false}>
            <InteresseCadastroView interesse={interesses} empreendimento={dadosOportunidade.id_empreendimento}/>
          </DommusToggle>
        </div>
        <div className="toggleCadastro">
          <DommusToggle
            title={
              <a
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setTabIndex(2);
                }}
              >
                Atendimento{" "}
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  style={{ marginLeft: 5 }}
                />
              </a>
            }
            toggle={false}
          >
            <Atendimento  atualizarEnvolvidos={atualizarEnvolvidos}  history={{}} oportunidade={oportunidade} dados={atendimentos} setTabIndex={undefined} atualizarOportunidade={setRefreshEnvolvido}/>
          </DommusToggle>
        </div>

        <div className="toggleCadastro">
          <DommusToggle
            title={
              <a
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setTabIndex(3);
                }}
              >
                Anexos{" "}
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  style={{ marginLeft: 5 }}
                />
              </a>
            }
            toggle={false}
          >
            <Anexos history={{}} oportunidade={oportunidade} dados={anexos} />
          </DommusToggle>
        </div>
        <div className="toggleCadastro">
          <DommusToggle
            title={
              <a
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setTabIndex(4);
                }}
              >
                Pendências{" "}
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  style={{ marginLeft: 5 }}
                />
              </a>
            }
            toggle={false}
          >
            <Pendencias history={{}} oportunidade={oportunidade} dados={pendencias} />
          </DommusToggle>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="bloco-conteudo">
        {(isDesktop || isTablet) ?
          itensDesktop :
          itensMobile
        }
        <ModalDommus
          open={openNovoCadastro}
          close={setOpenNovoCadastro.bind(false)}
          titulo="Cadastro Envolvido"
        >
          <NovoCadastro
            envolvido={envolvido}
            atualizarLista={atualizarEnvolvidos}
            id_oportunidade={oportunidade.id_oportunidade}
            envolvidos={clientes}
            closeModal={()=>{setOpenNovoCadastro(false)}}
          />
        </ModalDommus>

        <ModalDommus
          open={openModalEditarLead}
          close={setOpenModalEditarLead.bind(false)}
          titulo="Editar Lead"
        >
          <EditarLead
              atualizarLista={atualizarEnvolvidos}
              refresh={refresh}
              closeModal={() => setOpenModalEditarLead(false)}
              dados={dadosLead.lead}
              oportunidade={oportunidade.id_oportunidade}
            />
        </ModalDommus>

        <ModalDommus
          open={openModalMudarOPV}
          close={setOpenModalMudarOPV.bind(false)}
          titulo="Desvincular Lead"
        >
          <MudarParaOutraOPV
              atualizarLista={atualizarEnvolvidos}
              refresh={refresh}
              closeModal={() => setOpenModalMudarOPV(false)}
              dados={dadosLead.lead}
              dadosOportunidade={dadosOportunidade.id_oportunidade}
              oportunidade={oportunidade}
            />
        </ModalDommus>

        <ModalDommus
          open={openModalAtendimento}
          close={setOpenModalAtendimento.bind(false)}
          titulo={"Nova Atividade"}
        >
          <NovoAtendimento

              close={() => {
                setOpenModalAtendimento(false);
                setRefreshEnvolvido(!refreshEnvolvido);
              }}
              oportunidade={oportunidade?.id_oportunidade}
              atendimento={{}}
              inserirLista={()=>{}}
              index={1}
              setTabIndex={setTabIndex}
              atualizaLista={()=>{}}
            />
        </ModalDommus>

        <ModalDommus
        open={openModalAnexo}
        close={setOpenModalAnexo.bind(false)}
        titulo="Novo Anexo"
        >
          <NovoAnexo
            oportunidade={oportunidade}
            inserirLista={()=>{}}
            anexos={anexos}
            refresh={()=>{
              setOpenModalAnexo(false);
              setRefreshEnvolvido(!refreshEnvolvido);
            }}
          />
        </ModalDommus>

        <ModalDommus
          open={openModalPendencia}
          close={setOpenModalPendencia.bind(false)}
          titulo="Nova Pendencia"
        >
          <NovaPendencia
            oportunidade={oportunidade.id_oportunidade}
            inserirLista={()=>{}}
            refresh={()=>{
              setOpenModalPendencia(false);
              setRefreshEnvolvido(!refreshEnvolvido);
            }}
          />
        </ModalDommus>
        {oportunidade?.usuario_pode_editar &&
          <DropdownButton
            id={"dropdown-button-drop-up"}
            drop={"up"}
            className="buttonNovaOportunidade"
            title={<FontAwesomeIcon icon={faPlus} color="#fff" />}
          >
            {clientes.map((item, index) => (
              <Dropdown.Item
                eventKey="index"
                key={index}
                onClick={() => editEnvolvido(item)}
              >
                Editar: {item.nome}
              </Dropdown.Item>
            ))}
            <Dropdown.Item eventKey="4" onClick={() => abrirCadastro()}>
              Cadastrar Envolvido
            </Dropdown.Item>
            <Dropdown.Divider/>
          <Dropdown.Item
            eventKey="atendimento"
            onClick={()=>{setOpenModalAtendimento(true)}}
          >
            Nova Atividade
          </Dropdown.Item>
          <Dropdown.Item
            eventKey="anexo"
            onClick={()=>{setOpenModalAnexo(true)}}
          >
            Novo Anexo
          </Dropdown.Item>
          <Dropdown.Item
            eventKey="pendencia"
            onClick={()=>{setOpenModalPendencia(true)}}
          >
            Nova Pendência
          </Dropdown.Item>
        </DropdownButton>
        }
      </div>
      <TagOportunidadeFormulario
          openModal={openModalTagsOportunidade}
          setOpenModal={setOpenModalTagsOportunidade}
          oportunidade={oportunidade}
          atualizarOportunidade={atualizarOportunidade}
      />
      <ModalDommus
        open={openModalMobile}
        close={setopenModalMobile.bind(false)}
        titulo="Historico"
      >
        <HistoricoLead idLead={leadSelecionado} idOportunidade={oportunidade.id_oportunidade}/>
      </ModalDommus>

      <ModalDommus
        open={openModalNvLead}
        close={setopenModalNvLead.bind(false)}
        titulo="Novo Lead"
      >
        <NovoLeadOPV
            closeModal={() => {
              setopenModalNvLead(false);
            }}
            oportunidade={oportunidade}
        />
      </ModalDommus>
    </>
  );
}
